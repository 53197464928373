import { Routes, Route } from 'react-router-dom'

import Functions from './Functions'
import Events from './Events'
import Home from './Home'

function App() {
   return (
      <div align='center' style={{margin:'0 auto'}}>
         <img src='images/logo.png' style={{width:'100%', maxWidth:500}}/>
         <Routes>
            <Route path='/functions' element={<Functions />} />
            <Route path='/events' element={<Events />} />
            <Route path='/*' element={<Home />} />
         </Routes>
      </div>
   )
}
export default App
