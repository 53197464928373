import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Text from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'

function Functions() {
   return (
      <Container maxWidth='lg' style={{ padding: 10, marginTop:20, marginBottom:50 }}>
         <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12} md={10} >
               <Paper elevation={6} style={{border:'2px solid black', maxWidth:1000, marginBottom:100, padding:5, paddingBottom:100 }} >
                  <Text  variant='h5'>Function @ RockBack on the Bay</Text> 
                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text  variant='body1'>
                        <b>Need a place to host your special event? We can accommodate you! </b>
                        Located in beautiful Harts Bay, Chaguaramas. The ideal location for your special event.  Every event overlooks a scenic waterfront which creates a natural backdrop to the already impressive set up. Our event space offers our clients the right mix of natural beauty and elegance with a touch of oceanic, rustic charm. The attractive venue can be used to host your next event, from pop ups to weddings, to other private events, and make the most memorable time for both you and your guests. Here are the type of events that we can accommodate:
                     </Text>                  
                  </div>
                  <Grid container justifyContent='space-evenly' spacing={2} style={{padding:10, maxWidth:600}}>
                     <Grid item xs={12} sm={6} style={{textAlign:'left'}}>
                        Wedding Ceremonies & Receptions<br/>
                        Birthday Parties<br/>
                        Engagements<br/>
                        Fun Nights<br/>
                        Small Groups<br/>
                        Meetings
                     </Grid>
                     <Grid item xs={12} sm={6} style={{textAlign:'left'}}>
                        Corporate Trainings<br/>
                        Workshops<br/>
                        Seminars<br/>
                        Graduations<br/>
                        Family Events<br/>
                        ...and much more!<br/>
                     </Grid>
                  </Grid>
                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text  variant='body1'>
                        We have a very simple package structure that can accommodate a wide array of events. But if you would like us to work with you to tailor a package, please do not hesitate to reach out, our team is more than willing to help make your special event a reality. 
                     </Text>                  
                  </div>

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Venue Rental</Text> 
                     <Text  variant='body1'>
                        RockBack offers a standard venue rental fee based on the number of guests. According to the type of event, we can accommodate the following: 
                     </Text> 
                     <ul>
                        <li>120 seated guests in a dining setup</li>
                        <li>Up to 300 guests in a cocktail type setup with mixed seating and standing</li>
                        <li>600 guest in a party type setting</li>  
                     </ul>
                     <Text  variant='body1'>
                        All venue rentals include:  
                     </Text> 
                     <ul>
                        <li>Sound System</li>
                        <li>Plants & Furniture</li>
                        <li>Custodian & Toiletries</li>
                        <li>Parking Attendents</li>
                        <li>Event Coordinator</li>  
                     </ul> 

                     <Text  variant='body1'>
                        If you are having an event and would like the use of the venue exclusively, we offer the following packages  
                     </Text> 
                  </div>
                  <Grid container justifyContent='space-evenly' spacing={2} style={{padding:10, maxWidth:600}}>
                     <Grid item xs={12} sm={6}>
                        <Text variant='h6'>100 or less guests</Text>
                        <Text variant='h6'>$12,500</Text>
                     </Grid>
                     <Grid item xs={12} sm={6}>
                        <Text variant='h6'>101 or more guests</Text>
                        <Text variant='h6'>$16,000</Text>
                     </Grid>
                  </Grid>

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Bar Service</Text> 
                     <Text  variant='body1'>
                        Not all events require a bar, but for those that do, we offer the following options. We can also work with you to customize a package that will best suit your event.  
                     </Text> 
                  </div> 
                  <div style={{padding:20, textAlign:'left'}}>
                     <Text variant='subtitle1'><b>Cash Bar</b></Text> 
                     <Text  variant='body1'>
                        With a cash bar we provide a fully stocked bar for your guests to purchase drinks. This bar is no additional expense to you and is managed and operated by our staff. We will work with you to make sure we are stocked with the drinks you require.  
                     </Text> 
                     <div align='right'><Text variant='h6'><b>Cost: $0</b></Text></div>
                     <Divider/>
                  </div>  
                  
                  <div style={{padding:20, textAlign:'left'}}>
                     <Text variant='subtitle1'><b>Full Service Bar</b></Text> 
                     <Text  variant='body1'>
                        With a full service bar we provide a fully stocked bar with the appropriate amount of staff to service your guests. Select a drinks package from the attached list and pay a per head amount for your bar. (eg: 100 guests x $150 drinks package = 15,000). If you would like to bring in additional drinks, we charge a $50 per bottle corkage fee. We can work with you to design the appropriate drinks menu for your guests.  
                     </Text> 
                     <div align='right'><Text variant='h6'><b>Cost: # Guests x Per Head</b></Text></div>
                     <Divider/>
                  </div> 

                  <div style={{padding:20, textAlign:'left'}}>
                     <Text variant='subtitle1'><b>Bring Your Own Bar</b></Text> 
                     <Text  variant='body1'>
                        We can also work with you, If you would like to manage your own bar or hire an Outside Service Provider. To accommodate this we charge a facility fee.   
                     </Text> 
                     <div align='right'>
                        <Text variant='h6' style={{lineHeight:1}}><b>Cost: $2,500</b></Text>
                        <Text variant='caption' style={{color:'lightGray'}}><i>*May vary slightly based on number of guests</i></Text>
                     </div>
                     <Divider/>
                  </div> 

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Food Service</Text> 
                     <Text  variant='body1'>
                        We have an on site kitchen/grill that can provide a full grill menu. We can also provide cutters, appetizers and hors d'oeuvres for your event. If you require a different or more extensive menu we can help you select the right caterer for your event. Apart from the menu, here are the food service options we offer.  
                     </Text> 
                  </div>
                  <div style={{padding:20, textAlign:'left'}}>
                     <Text variant='subtitle1'><b>Cash Grill</b></Text> 
                     <Text  variant='body1'>
                        With a cash grill we provide a full grill menu for your guests to purchase. This grill is no additional expense to you and is managed and operated by our staff. We will work with you to adjust our menu to satisfy your guests.  
                     </Text> 
                     <div align='right'><Text variant='h6'><b>Cost: $0</b></Text></div>
                     <Divider/>
                  </div> 
                  <div style={{padding:20, textAlign:'left'}}>
                     <Text variant='subtitle1'><b>Caterer Service</b></Text> 
                     <Text  variant='body1'>
                        We can also work with you, If you would like to manage your own food service or hire an Outside Service Provider. To accommodate this we charge a facility fee.   
                     </Text> 
                     <div align='right'>
                        <Text variant='h6' style={{lineHeight:1}}><b>Cost: $2,500</b></Text>
                        <Text variant='caption' style={{color:'lightGray'}}><i>*May vary slightly based on number of guests</i></Text>
                     </div>
                     <Divider/>
                  </div>  

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Site Visit</Text> 
                     <Text  variant='body1'>If you would like to view the venue, please contact us at 716-4828. All site visits must be booked in advance.</Text>
                  </div>

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Booking & Payment Terms</Text> 
                     <Text  variant='body1'>To confirm a booking of our venue a 50% Non-Refundable deposit is required with the final payment due two (2) weeks before your event. Before payment is made, a contract will be made available for signature which contains all terms and conditions of your rental.</Text>
                  </div>

                  <Divider style={{margin:15, border:'1px solid gray'}}/>

                  <div style={{marginTop:10, padding:5, textAlign:'left'}}>
                     <Text variant='h5'>Contact Us</Text> 
                     <Text  variant='body1'>To check on the availability of a date, book a site visit or to find out more. Please do not hesitate to give us a call on 716-4828.</Text>
                  </div>


               </Paper>
            </Grid>
         </Grid>
      </Container>      
   )
}
export default Functions
