import { createTheme  } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    type: 'light',
    background: {
      default:'#da6d42'
    },
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#ff1493',
    },
    red: {
      main: '#f00707',
    },
    yellow: {
      main: '#f0c907',
    },
    green: {
      main: '#0ec92a',
    },
  }
})
/*
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    background: {
      default:'#E6EBF0'
    },
    primary: {
      main: '#000000',//red[600],
      sub: red[300]
    },
    secondary: {
      main: '#ab47bc'
    }
  },
  overrides: {
    MuiBottomNavigation: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        backgroundColor: '#f2f2f2', // Some CSS
        zIndex: 4 // To over ride creative tim's components
      }
    },

    MuiAppBar: { // Name of the component ⚛️ / style sheet
      colorDefault: {
        backgroundColor: '#cfd8dc',
      }
    },
    MuiFilledInput: {
      root: {
          border: '1px solid #cfd8dc',
          backgroundColor: '#E6EBF0',
          '&:hover': {
            backgroundColor: '#cfd8dc',
          }
      }
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: '#eceff1',
        }
      }
    },
    MuiCardActionArea: {
      root: {
        '&:hover': {
          backgroundColor: '#eceff1',
        }
      }
    }
  }
})
*/
export default theme
//https://v0.material-ui.com/#/customization/themes
/*
var zIndex = {
  mobileStepper: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
};
*/